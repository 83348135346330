import React from 'react'
import { graphql } from 'gatsby'

// Components

// import Container from '../components/Container'
import DividingLine from '../components/DividingLine'
import Footer from '../components/Footer'
import HalfAndHalfText from '../components/5050Text'
import Hero from '../components/Hero'
import LearnMore from '../components/LearnMore'
import ScrollSection from '../components/ScrollSection'
import Seo from '../components/Seo'
import TextRepeater from '../components/TextRepeater'
import InViewSection from '../components/InViewSection'
import WellbeingTitle from '../components/WellbeingTitle'

const GuidelinesPage = ({ data }) => {
  const {
    heroTitle,
    rightOrLeftAlignedText,
    text,
    scopeTitle,
    scopeCopy,
    scopeOverlay,
    guidelinePrinciplesTitle,
    guidelinePrinciplesCopy,
    guidelinePrinciplesButton,
    supportedUseCasesTitle,
    supportedUseCasesCopy,
    supportedUseCasesButton,
    unsupportedUseCasesTitle,
    unsupportedUseCasesCopy,
    unsupportedUseCasesButton,
    wellbeingTitle,
    wellbeingCopy,
    wellbeingButton,
    rightOrLeftAlignedText2,
    text2,
    learnMore,
    seoTitle,
    seoMetaDescription,
  } = data.allContentfulPageGuidelines.edges[0].node

  return (
    <>
      <Seo
        title={seoTitle ? seoTitle : `Guidelines`}
        description={seoMetaDescription}
      />

      <ScrollSection>
        <>
          <Hero home={false} title={heroTitle} />
          <DividingLine />
        </>
      </ScrollSection>

      <TextRepeater repeaterContent={text} start={rightOrLeftAlignedText} />

      <ScrollSection>
        <DividingLine />
      </ScrollSection>

      <ScrollSection trackInView={false}>
        <>
          <HalfAndHalfText
            title1={scopeTitle}
            copy1={scopeCopy}
            overlay1={scopeOverlay}
            title2={guidelinePrinciplesTitle}
            copy2={guidelinePrinciplesCopy}
            button2={guidelinePrinciplesButton}
          />

          <InViewSection>
            <DividingLine />
          </InViewSection>

          <WellbeingTitle title={wellbeingTitle} buttonText={wellbeingButton.buttonText} buttonLink={wellbeingButton.buttonLink} copy={wellbeingCopy.wellbeingCopy}/>

          <InViewSection>
            <DividingLine />
          </InViewSection>

          <HalfAndHalfText
            title1={supportedUseCasesTitle}
            copy1={supportedUseCasesCopy}
            button1={supportedUseCasesButton}
            title2={unsupportedUseCasesTitle}
            copy2={unsupportedUseCasesCopy}
            button2={unsupportedUseCasesButton}
          />
        </>
      </ScrollSection>

      <ScrollSection>
        <DividingLine />
      </ScrollSection>

      <TextRepeater repeaterContent={text2} start={rightOrLeftAlignedText2} />

      <ScrollSection>
        <>
          <DividingLine />
          <LearnMore content={learnMore} />
          <DividingLine />
        </>
      </ScrollSection>

      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default GuidelinesPage

export const pageQuery = graphql`
  query GuidelinesQuery {
    allContentfulPageGuidelines(filter: { slug: { eq: "guidelines" } }) {
      edges {
        node {
          wellbeingTitle
          wellbeingCopy {
            wellbeingCopy
          }
          wellbeingButton {
            buttonText
            buttonLink
          }
          slug
          heroTitle
          seoTitle
          seoMetaDescription {
            seoMetaDescription
          }
          rightOrLeftAlignedText
          text {
            title
            copy {
              raw
            }
          }
          scopeTitle
          scopeCopy {
            raw
          }
          scopeOverlay {
            raw
          }
          guidelinePrinciplesTitle
          guidelinePrinciplesCopy {
            raw
          }
          guidelinePrinciplesButton {
            buttonText
            buttonLink
          }
          supportedUseCasesTitle
          supportedUseCasesCopy {
            raw
          }
          supportedUseCasesButton {
            buttonText
            buttonLink
          }
          unsupportedUseCasesTitle
          unsupportedUseCasesCopy {
            raw
          }
          unsupportedUseCasesButton {
            buttonText
            buttonLink
          }
          rightOrLeftAlignedText2
          text2 {
            title
            copy {
              raw
            }
          }
          learnMore {
            title
            copy {
              raw
            }
            buttonText
            supportTitle
          }
        }
      }
    }
  }
`
