import React from 'react';
import styled from 'styled-components'
import colors from '/src/styles/ReformStyles/colors'
import media from '/src/styles/ReformStyles/media'
import PageTransitionLink from '../PageTransitionLink/index'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const WellbeingTitle = ({title, copy, buttonLink, buttonText}) => {
  return (
    <InViewSection>
      <AnimateFadeIn>
        <WellbeingWrapper>
          <WellbeingHeader>
            {title}
          </WellbeingHeader>
          <WellbeingBody>
            {copy}
          </WellbeingBody>
          <PageTransitionLink to={buttonLink} underline={false} padded={'false'}>
            <WellbeingButton>
              <ButtonText>
                {buttonText}
              </ButtonText>
            </WellbeingButton>
          </PageTransitionLink>
        </WellbeingWrapper>
      </AnimateFadeIn>
    </InViewSection>
  )
}

const WellbeingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 48.472vw;
  justify-content: center;
  padding: 12.122vw 12.878vw 15.961vw 13.603vw;

  ${media.fullWidth} {
    height: 698px;
    padding: 12.153vw 12.847vw 15.972vw 15vw;
  }

  ${media.tablet} {
    height: 67vw;
    padding: 14.648vw 7.813vw 14.648vw 1vw;
  }

  ${media.mobile} {
    height: 130vw;
    padding: 14.648vw 7.813vw 14.648vw 1vw;
  }
`
const WellbeingHeader = styled.p`
  font-family: 'Nib';
  font-style: normal;
  font-weight: 700;
  font-size: 3.628vw;
  line-height: 5vw;
  margin-bottom: 2.778vw;

  ${media.fullWidth} {
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 72px;
    margin-bottom: 40px;
  }

  ${media.tablet} {
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 5vw;
    line-height: 72px;
    margin-bottom: 1.2vw;
  }

  ${media.mobile} {
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 9vw;
    line-height: 16vw;
    margin-bottom: 1.5vw;
  }
`
const WellbeingBody = styled.p`
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1.511vw;
  line-height: 1.806vw;
  margin-bottom: 2.083vw;
  width: 73.519vw;

  ${media.fullWidth} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 30px;
    width: 1059px;
  }

  ${media.tablet} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 2.4vw;
    line-height: 2.75vw;
    margin-bottom: 2.929vw;
    width: 75vw;
  }

  ${media.mobile} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 4.8vw;
    line-height: 5.5vw;
    margin-bottom: 3.9vw;
    width: 85vw;
  }
`
const ButtonText = styled.p`
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.907vw;
  line-height: 1.111vw;
  text-align: center;
  text-transform: uppercase;

  ${media.fullWidth} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }

  ${media.tablet} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.65vw;
    line-height: 1.2vw;
  }

  ${media.mobile} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 3.482vw;
    line-height: 7vw;
  }
`
const WellbeingButton = styled.div`
  width: 17.775vw;
  height: 4.292vw;
  border: 1px solid ${colors.altLight};
  border-radius: 3.023vw;
  padding-top: 1.5vw;

  ${media.fullWidth} {
    width: 256px;
    height: 62px;
    border-radius: 44px;
    padding-top: 22px;
  }

  ${media.tablet} {
    margin-top: 4vw;
    width: 24.996vw;
    height: 5.5vw;
    border-radius: 4.251vw;
    padding-top: 2.109vw;
  }

  ${media.mobile} {
    margin-top: 15vw;
    width: 60vw;
    height: 12vw;
    border-radius: 8vw;
    padding-top: 2.109vw;
  }
`
export default WellbeingTitle