import React, { useState, useRef } from 'react'
import { Heading2 } from '../../styles/vars/textStyles.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'

// Components
import DividingLine from '../DividingLine'
import GetInTouch from '../GetInTouchButton'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'
import Button from '../Button'

import {
  LearnMoreMain,
  ButtonWrap,
  Support,
  SupportTitle,
  SupportForm,
  SupportInput,
  SupportSubmit,
  SupportThanks,
  SupportThanksText,
  SupportMain,
  LearnMoreDivider,
} from './index.style'

const LearnMore = ({ content }) => {
  const [supportSubmitting, setSupportSubmitting] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState(null),
    [formFields, setFormFields] = useState({
      name: '',
      email: '',
      jobTitle: '',
      affiliation: '',
    })

  const formRef = useRef(null)

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      if (key === 'fileUpload') {
        formData.append(key, data[key][0])
      } else {
        formData.append(key, data[key])
      }
    }
    return formData
  }

  const handleSubmit = e => {
    e.preventDefault()

    setSupportSubmitting(true)

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': 'learn-more',
        ...formFields,
      }),
    })
      .then(response => {
        setFeedbackMessage('Thanks for your support!')
        formRef.current.reset()
      })
      .catch(err => setFeedbackMessage('Error, please try again.'))
  }

  return (
    <LearnMoreMain>
      <AnimateFadeIn>
        <Grid>
          <GridItem
            mobile={14}
            mobileStart={2}
            tabletL={5}
            tabletLStart={3}
            desk={4}
            deskStart={3}
            deskL={4}
            deskLStart={3}
          >
            <Heading2>{content.title}</Heading2>
            <RichText content={content.copy} />
          </GridItem>

          <GridItem
            mobile={14}
            mobileStart={2}
            tabletL={4}
            tabletLStart={11}
            deskL={2}
            deskLStart={13}
          >
            <ButtonWrap>
              <GetInTouch hasborder text={content.buttonText} />
            </ButtonWrap>
          </GridItem>

          <GridItem mobile={14} mobileStart={2} tabletL={12} tabletLStart={3}>
            <LearnMoreDivider>
              <DividingLine contain />
            </LearnMoreDivider>
          </GridItem>
        </Grid>
      </AnimateFadeIn>

      <AnimateFadeIn>
        <Support>
          <SupportMain show={!feedbackMessage} aria-hidden={feedbackMessage}>
            <Grid>
              <GridItem
                mobile={14}
                mobileStart={2}
                mobileL={10}
                mobileLStart={4}
                tabletL={5}
                tabletLStart={3}
              >
                <SupportTitle>{content.supportTitle}</SupportTitle>
              </GridItem>
            </Grid>

            <SupportForm
              encType="multipart/form-data"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="post"
              name={`learn-more`}
              onSubmit={event => handleSubmit(event)}
              ref={formRef}
            >
              {/**mandatory netlify form fields */}
              <input type="hidden" name="bot-field" />
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="learn-more" />
              <Grid>
                <GridItem
                  mobile={14}
                  mobileStart={2}
                  mobileL={10}
                  mobileLStart={4}
                  tabletL={6}
                  tabletLStart={3}
                >
                  <SupportInput
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Full Name*"
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        name: e.target.value,
                      })
                    }}
                    required
                  />
                </GridItem>

                <GridItem
                  mobile={14}
                  mobileStart={2}
                  mobileL={10}
                  mobileLStart={4}
                  tabletL={6}
                >
                  <SupportInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        email: e.target.value,
                      })
                    }}
                    required
                  />
                </GridItem>

                <GridItem
                  mobile={14}
                  mobileStart={2}
                  mobileL={10}
                  mobileLStart={4}
                  tabletL={6}
                  tabletLStart={3}
                >
                  <SupportInput
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    placeholder="Job Title*"
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        jobTitle: e.target.value,
                      })
                    }}
                    required
                  />
                </GridItem>

                <GridItem
                  mobile={14}
                  mobileStart={2}
                  mobileL={10}
                  mobileLStart={4}
                  tabletL={6}
                >
                  <SupportInput
                    type="text"
                    name="affiliation"
                    id="affiliation"
                    placeholder="Affiliation*"
                    onChange={e => {
                      setFormFields({
                        ...formFields,
                        affiliation: e.target.value,
                      })
                    }}
                    required
                  />
                </GridItem>

                <GridItem
                  mobileL={10}
                  mobileLStart={4}
                  tabletL={12}
                  tabletLStart={3}
                >
                  <SupportSubmit>
                    <Button
                      type="submit"
                      text={supportSubmitting ? 'Submitting' : 'Submit'}
                      hasborder
                      theme={2}
                    />
                  </SupportSubmit>
                </GridItem>
              </Grid>
            </SupportForm>
          </SupportMain>

          <SupportThanks show={feedbackMessage} aria-hidden={!feedbackMessage}>
            {feedbackMessage && (
              <SupportThanksText>{feedbackMessage}</SupportThanksText>
            )}
          </SupportThanks>
        </Support>
      </AnimateFadeIn>
    </LearnMoreMain>
  )
}

export default LearnMore
