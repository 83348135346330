import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { Button } from '../Button/index.style'

export const LearnMoreMain = styled.div`
  padding-bottom: 7.4rem;
  padding-top: 7.4rem;
  text-align: center;

  ${mq.tabletL} {
    text-align: left;
  }

  ${mq.deskL} {
    padding-top: 17rem;
    padding-bottom: 17rem;
  }
`

export const LearnMoreDivider = styled.div`
  padding-top: 5rem;
`

export const ButtonWrap = styled.div`
  margin-top: 4rem;

  ${mq.tabletL} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0;
    text-align: right;
  }
`

export const Support = styled.div`
  position: relative;
`

export const SupportTitle = styled.h3`
  padding-top: 5rem;
  font-family: ${font.secondary.family};
  font-weight: ${font.secondary.weight.semibold};
  ${clamp('font-size', 20, 30)}
  line-height: 1.2;
`

export const SupportForm = styled.form`
  ${clamp('margin-top', 25, 40)}
`

export const SupportInput = styled.input`
  width: 100%;
  margin-bottom: 0.6rem;
  padding: 0.9473684211em 2em 1em;
  font-family: ${font.secondary.family};
  font-weight: ${font.secondary.weight.medium};
  ${clamp('font-size', 16, 19)}
  line-height: 1.35;
  color: ${colors.dark};
  border: 0.1rem solid ${colors.dark};
  border-radius: 1rem;
  background-color: transparent;

  ::placeholder {
    color: ${colors.dark};
  }
`

export const SupportSubmit = styled.div`
  text-align: center;

  ${mq.tabletL} {
    text-align: right;
  }

  ${Button} {
    margin-top: 0.7rem;
  }
`

export const SupportMain = styled.div`
  pointer-events: ${props => (props.show ? undefined : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 1s ${easings.modal.default};
`

export const SupportThanks = styled.div`
  position: absolute;
  top: 5.1rem;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 1s ${easings.modal.default};
`

export const SupportThanksText = styled.h3`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  ${clamp('font-size', 30, 60)}
  line-height: 1.1;
  letter-spacing: -0.05em;
`
